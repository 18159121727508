import React from 'react';

const Head = ({TituloPage}) => {

    return (
        <>
            <title>{TituloPage}</title>
            <meta name="description" content="
                Vega I.T. - Empresa especializada em soluções em tecnologias 
                para serviços de hospedagem e hotelaria"
            />
        </>
    )
}

export default Head;