import React from 'react';
import { FaFacebook, FaLinkedin, FaInstagram, FaPhone, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';
import './style.scss';

const Footer = () => {
    const InfosFooter = {
        AltPadrao: 'VEGA I.T. - Soluções em tecnologia',
    }
    return (
        <footer>
            <div class="footer-flex wrapper">
                <div class="footer-flex-logo">
                    <div>
                        <a href="https://vegait.com/">
                            <StaticImage src="../../images/Vega.png" placeholder='blurred' loading='lazy' alt={`${InfosFooter.AltPadrao}`}/>
                        </a>
                    </div>
                    <div className="wrap-btn-footer">
                        <a href="https://api.whatsapp.com/send?phone=554132040300" className="btn btn-footer btn-footer-whatsapp" target="blank" alt={`${InfosFooter.AltPadrao}`}>
                            <span>
                                <FaWhatsapp/>
                            </span> 
                            WhatsApp
                        </a>
                        <a href="tel:554132040300" className="btn btn-footer btn-footer-contato" alt={`${InfosFooter.AltPadrao}`}>
                            <StaticImage className='footer-bandeira' src='../../images/BR.png' placeholder='blurred' loading='eager' alt={`${InfosFooter.AltPadrao}`}/>
                            <span>
                                <FaPhone />
                            </span>
                            (41) 3204-0300
                        </a>
                        <a href="tel:18889030233" className="btn btn-footer btn-footer-contato" alt={`${InfosFooter.AltPadrao}`}>
                            <StaticImage className='footer-bandeira' src='../../images/EUA.png' placeholder='blurred' loading='eager' alt={`${InfosFooter.AltPadrao}`}/>
                            <span>
                                <FaPhone />
                            </span>
                            +1 8889030233
                        </a>
                    </div>

                    <div className="footer-flex-logo-social">
                        <a href="https://www.linkedin.com/company/vegait" target="blank" alt={`${InfosFooter.AltPadrao}`}>
                            <FaLinkedin />
                        </a>
                        <a href="https://www.facebook.com/vegait" target="blank" alt={`${InfosFooter.AltPadrao}`}>
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/vegait/" target="blank" alt={`${InfosFooter.AltPadrao}`}>
                            <FaInstagram />
                        </a>
                        <a href="https://www.youtube.com/c/VEGAITtecnologia" target="blank" alt={`${InfosFooter.AltPadrao}`}>
                            <FaYoutube />
                        </a>
                    </div>
                </div>

                <div className="footer-flex-vega">
                    <h5><a href="/sobre-nos">A VEGA I.T.</a></h5>
                    <ul>
                        <li>
                            <a href="https://vegait.com/clientes" className="active-link" alt={`${InfosFooter.AltPadrao}`}>
                                Clientes
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/na-midia" alt={`${InfosFooter.AltPadrao}`}>
                                Na mídia
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/blog/" alt={`${InfosFooter.AltPadrao}`}>
                                Blog
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.solides.jobs/" alt={`${InfosFooter.AltPadrao}`}>
                                Trabalhe Conosco
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/seja-um-parceiro" alt={`${InfosFooter.AltPadrao}`}>
                                Seja um parceiro
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/contato" alt={`${InfosFooter.AltPadrao}`}>
                                Contato
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/parceiros-e-integracoes" alt={`${InfosFooter.AltPadrao}`}>
                                Parceiros e Integrações
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="footer-flex-solucoes">
                    <h5>
                        <a href="https://vegait.com/nossas-solucoes" alt={`${InfosFooter.AltPadrao}`}>
                            Nossas soluções
                        </a>
                    </h5>
                    <ul>
                        <li>
                            <a href="https://vegait.com/internet-e-wifi" className="active-link" alt={`${InfosFooter.AltPadrao}`}>
                                Internet &amp; Wi-Fi
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/marketing-digital" alt={`${InfosFooter.AltPadrao}`}>
                                Marketing Digital para Hotéis
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/hxp-hotel-experience" alt={`${InfosFooter.AltPadrao}`}>
                                Hotel Experience
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/easy-check-in" alt={`${InfosFooter.AltPadrao}`}>
                                Easy Check-In
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/sistema-integrado-manutencao-sim" alt={`${InfosFooter.AltPadrao}`}>
                                Sistema Integrado de Manutenção - SIM
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/virtual-smart-key-vs-key" alt={`${InfosFooter.AltPadrao}`}>
                                Virtual Smart Key - VSKey
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/feedback" alt={`${InfosFooter.AltPadrao}`}>
                                VEGA Feedback
                            </a>
                        </li>
                        <li>
                            <a href="https://vegait.com/vega-behavior" alt={`${InfosFooter.AltPadrao}`}>
                                VEGA Behavior
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="footer-flex-enderecos">
                    <h5>Curitiba</h5>
                    <ul>
                        <li>Rua São Pio X, 831</li>
                        <li>Ahú, Curitiba - PR | Brasil</li>
                    </ul>

                    <h5>Orlando</h5>
                    <ul>
                        <li>2815 Directors Row STE Office</li>
                        <li>506 - Orlando - FL | USA</li>
                    </ul>


                </div>
            </div>

            <div className="footer-bottom">
                <div className="wrapper">
                    <p>VEGA I.T. | Construindo o futuro da hospitalidade</p>
                    <p>Todos os direitos reservados ® 2022</p>
                </div>
            </div>

        </footer>
    )
}

export default Footer;