import * as React from "react"

import Head from '../Components/Seo/';
import Header from "../Components/Header/";
import FormularioNode from "../Components/Formulario/";
import Footer from "../Components/Footer/";

const IndexPage = () => {
  return (
    <>
      <Head TituloPage={'VEGA I.T. - Atualizações'}/>
      <Header/>
      <FormularioNode/>
      <Footer/>
    </>
  )
}

export default IndexPage;