import React, { useState } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import axios from 'axios';
import './style.scss';
import ModalSucesso from '../modalSucess/';

const FormularioNode = () => {

  const [sucesso, setSucesso] = useState(false)
  const [carregando, setCarregando] = useState(false)

  function send() {
    setCarregando(true)
    const formData = new FormData()
    var anexos = Array.from(campos.anexo)

    Object.keys(campos).forEach(key => formData.append(key, campos[key]));
    
    anexos.map(item => {
      formData.append('anexo', item)
    })

    console.log(formData)

    axios({
      url: `https://atualizacoes.vegait.com.br/send`,
      // url: 'http://localhost:3060/send',
      method: 'POST',
      data: formData,
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      }
    })
      .then(response => {
        setSucesso(true)
        setCarregando(false)
      })
      .catch(err => {
        setSucesso(false)
        setCarregando(false)
      })
  }

  function handleFormSubmit(event) {
    send(campos);
    event.preventDefault();
    // console.log(campos); 
    // navigate('/')
  }
  const [campos, setCampos] = useState({
    nome: '',
    email: '',
    mensagem: '',
    site: '',
    empresa: '',
    anexo: []
  });
  function handleInputChange(event) {
    if (event.target.name === "anexo")
      // campos[event.target.name] = [...campos[event.target.name], event.target.files] ;
      campos[event.target.name] = event.target.files;
    else
      campos[event.target.name] = event.target.value;
    setCampos(campos);
  }

  return (

    <>
      {
        sucesso ?
          <>
            <ModalSucesso />
          </>
          :
          <>
            <section className='NovoForm-espacamento'>
              <main className="NovoForm-wrapper">
                <div className="NovoForm-menu-top-wrap">
                  <a href="https://vegait.com/" alt="VEGA I.T. - Soluções para hotelaria">A VEGA I.T.</a>
                  <i><IoIosArrowForward /></i>
                  <p alt="VEGA I.T. - Soluções para hotelaria">Atualizações</p>
                </div>
                <div className="NovoForm-text-main-wrap">
                  <h2>Atualizações</h2>
                  <p>
                    Faça sua solicitação de atualização preenchendo o formulário.
                  </p>
                </div>
              </main>
              <div className="container">
                <form method='post' onSubmit={handleFormSubmit}>
                  <label htmlFor="empresa">Nome da empresa</label>
                  <input type="text" id="empresa" name="empresa" placeholder="Nome da Empresa" onChange={handleInputChange} required />

                  <label htmlFor="site">Site</label>
                  <input type="text" id="site" name="site" placeholder="Sobre qual site você gostaria de falar?" onChange={handleInputChange} required />

                  <label htmlFor="nome">Nome</label>
                  <input type="text" id="nome" name="nome" placeholder="Nome da pessoa.." onChange={handleInputChange} required />

                  <label htmlFor="email">E-mail</label>
                  <input type="text" id="email" name="email" placeholder="E-mail de destino.." onChange={handleInputChange} required />

                  <label htmlFor="telefone">Telefone</label>
                  <input type="tel" id="telefone" name="telefone" placeholder="Digite seu telefone" onChange={handleInputChange} />

                  <label htmlFor="assunto">Assunto</label>
                  <input type="text" id="assunto" name="assunto" placeholder="Sobre o que gostaria de falar?" onChange={handleInputChange} />

                  <label htmlFor="mensagem">Mensagem</label>
                  <textarea className="textArea" id="mensagem" name="mensagem" placeholder="Escreva algo.." onChange={handleInputChange}></textarea>

                  <label htmlFor="anexo">Anexo</label>
                  <input type="file" id="anexo" name="anexo" onChange={handleInputChange} multiple />
                  <div className='atualizacao-btn-wrapper'>
                    {
                      carregando ?
                        <>
                          <div className="buttonorange-loader"></div>
                        </>
                        :
                        <>
                          <input className='Atualizacao-btn-principal' type="submit" value="Enviar" onChange={handleInputChange} />
                        </>
                    }
                  </div>
                </form>
              </div>
            </section>
          </>
      }
    </>
  );
}

export default FormularioNode;