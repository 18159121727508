import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import './style.scss';

const Header = () => {
    
    const infoHeader = {
        AltPadrao: 'Vega I.T. - Soluções em tecnologia para Hotéis',

    }

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-light fixed-top header-bg">
                
                <div class="container-fluid">
                    
                    <a href="https://vegait.com/" className="header-logo-wrapper">
                        <StaticImage className="header-logo-image" src="../../images/logoHeader.png" placeholder='Blurred' loading='lazy' alt={`${infoHeader.AltPadrao}`}/>
                    </a>
            
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse header-posicionamento" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0 Header-posicionamento-menu">
                            
                            <li className="nav-item">
                                <a href="https://vegait.com/" className="nav-link header-btn-menu-mobile" aria-current="page">
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="https://vegait.com/clientes" className="nav-link header-btn-menu-mobile" aria-current="page">
                                    Clientes
                                </a>
                            </li>
    
                            <li className="nav-item">
                                <a href="https://vegait.com/nossas-solucoes" className="nav-link header-btn-menu-mobile">
                                    Soluções
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="https://vegait.com/blog/" className="nav-link header-btn-menu-mobile">
                                    Blog
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="https://vegait.com/contato" className="nav-link header-btn-menu-mobile">
                                    Contato
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className='header-divider'></div>
        </>
    )
}

export default Header;