import React from 'react';
import { GiRocket } from 'react-icons/gi';
import './style.scss';

const ModalSucesso = () => {
    
    const InfosForm = {
        titulo: 'Sua solicitação foi recebida com sucesso!',
        describe: 'Em breve iremos analisar o status da sua solicitação e entraremos em contato.',
    }

    return (
        <div className='modal-wrapper'>
            <div className='modal-infos-wrapper'>
                <h3><GiRocket/></h3>
                <h2>{`${InfosForm.titulo}`}</h2>
                <p>{`${InfosForm.describe}`}</p>
                <div className='modal-infos-btn-wrap'>
                    <a href="https://vegait.com/" alt="Vega I.T. - soluções tecnologicas para hotéis">
                        Voltar ao Inicio
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ModalSucesso;
